<template>
  <div class="paginate">
    <button class="paginate prev" v-show="currentPage > 1" @click="currentPage--">
      <i class="fas fa-chevron-left"></i>
    </button>
    <button
      class="paginate"
      v-for="pageIndex in range(0, totalPages).slice(
        totalPages - currentPage < 4 && totalPages - currentPage > 5
          ? totalPages - 4
          : currentPage > 1
          ? currentPage - 1
          : 1,
        currentPage <= 1 ? currentPage + 5 : currentPage + 4
      )"
      :key="`current-page-${pageIndex}`"
      :class="{ active: pageIndex == currentPage }"
      @click="currentPage = pageIndex"
    >
      {{ pageIndex }}
    </button>
    <button class="paginate next" v-show="currentPage < totalPages" @click="currentPage++">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  watch: {
    currentPage: {
      handler(newVal) {
        this.onChangePage(newVal);
      },
    },
  },
  methods: {
    range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    onChangePage(page) {
      this.$emit('current', page);
    },
  },
};
</script>

<style lang="scss" scoped>
.paginate {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: relative;
  z-index: 20;
  .paginate {
    background: rgba($color: #9d9898, $alpha: 0.05);
    border-radius: 8px;
    width: 32px;
    height: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #2a2e4a;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    border: 1px solid #2a2e4a60;
    margin: 5px;
    &.next {
      background: #f2f2f2;
      color: #2a2e4a;
    }
    &.prev {
      background: #f2f2f2;
      color: #2a2e4a;
    }
    &.active {
      background: #FF5C00;
      color: #ffffff;
    }
  }
}
</style>
